.image-slider {
    height: 55vh;
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 0;
    top: 0;
    overflow: hidden;
}

.slider-container {
    display: flex;
    position: relative;
}

.slider-container img {
    position: absolute;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    object-fit: cover;
    height: 55vh;
    width: 100%;
    object-position: center;
}

.slider-container img.active {
    opacity: 1;
}

.image-slider button {
    display: none;
}